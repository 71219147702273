<template>
  <transition name="fade">
    <div
      class="modal-mask"
      ref="modalMask"
      v-on:keyup.esc="$emit('close')"
      tabindex="0"
    >
      <div class="modal-wrapper">
        <div class="modal-container" v-bind:class="containerClass">
          <div class="modal-header" v-if="title">
            <div class="row align-items-center">
              <div class="col-auto pr-0" v-if="icon && icon.length">
                <i v-bind:class="icon + ' fa-lg'"></i>
              </div>
              <div class="col-auto pl-0 rounded" v-else-if="titleImage">
                <img
                  v-bind:src="titleImage"
                  width="80"
                  class="img-fluid rounded"
                />
              </div>
              <div class="col">
                <h4 class="mb-0">{{ title }}</h4>
              </div>
            </div>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
          <button
            class="btn close-btn"
            v-if="showClose"
            @click="$emit('close')"
          >
            <i v-bind:class="closeIcon"></i>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "WeModal",
  props: {
    icon: {
      default: "",
    },
    title: {
      default: "",
    },
    titleImage: {
      default: "",
    },
    closeButtonText: {
      default: "Kapat",
    },
    modalWidth: {
      default: null,
    },
    showClose: {
      default: true,
    },
    closeIcon: {
      default: "fas fa-times",
    },
  },
  computed: {
    containerClass() {
      return this.modalWidth ? `modal-container--${this.modalWidth}` : null;
    },
  },
  mounted() {
    this.$refs.modalMask.focus();
  },
};
</script>

<style lang="scss" scoped>
.close-btn {
  position: absolute;
  top: 30px;
  right: 30px;
}
.modal-mask {
  position: fixed;
  z-index: 1060;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-footer {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0 !important;
  // padding: 0.75rem;
  // border-top: 1px solid #dee2e6;
  border-top: none !important;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.modal-container.modal-container--30 {
  width: 30%;
}
.modal-container.modal-container--50 {
  width: 50%;
}
.modal-container.modal-container--80 {
  width: 80%;
}

@media (max-width: 576px) {
  .modal-container {
    width: 90vw !important;
  }
}

@media (min-width: 576px) {
  .modal-container {
    width: 90vw;
  }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 992px) {
  .modal-container.modal-container--30 {
    width: 90%;
  }
}
@media (min-width: 768px) {
  .modal-container {
    width: 90vw;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .modal-container {
    width: 60vw;
  }
}
</style>
